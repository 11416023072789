/* eslint-disable max-len */
// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import cn from 'classnames';
import ReactSelect from 'react-select';

import LeadCreatedPopUp from 'client/components/common/LeadCreatedPopUp';

import { LEAD_CONSTANTS } from 'common/constants';

import { COLLECTIONS } from 'client/constants';
import { Responsive } from 'client/components/common';
import { createItem, updateRealTimeDataBase } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';

import uploadIcon from 'assets/client/images/firstScreen/upload.png';
import checkIcon from 'assets/client/images/createLeadPopUp/check.png';
import * as css from './ContentForm.scss';

type FormData = {
	nameOfTheCandle?: string,
	birthYear?: {
		value: string,
		label: string,
	},
	deathYear?: {
		value: string,
		label: string,
	},
	freeText?: string,
	mainPicture?: FileURL,
	profilePicture?: FileURL,
	senderName?: string,
};

type Props = {
	values: FormData,
	errors: FormData,
	createItem: typeof createItem,
	// updateRealTimeDataBase: typeof updateRealTimeDataBase,
	setActiveCreateLeadPopUp: any,
};

type State = {
	isShowSenededFormPopup: boolean,
	isLoading: boolean,
	isShowError: boolean,
};

// const maxWordLength = (value: string, maxWords: number) => {
// 	if (value === undefined) return maxWords + 1 < 0;

// 	return value.toString().split(' ').length > maxWords + 1;
// };

const FORM_CONFIG = {
	form: 'userInfo',
};

class ContentForm extends React.PureComponent<Props, State> {
	static defaultProps = {
		// className: '',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isShowSenededFormPopup: false,
			isLoading: false,
			isShowError: false,
			nameOfTheCandleValue: '',
			mainPicture: null,
			profilePictures: [],
			freeText: '',
			birthYear: '',
			deathYear: '',
			senderName: '',
			profilePicturesError: '',
			nameOfTheCandleError: '',
			mainPictureError: '',
		};
	}

	initFormValues = async () => {};

	onClickCloseCreatedPopUp = () => {
		this.setState({ isShowSenededFormPopup: false });
	};

	onFormSubmit = async e => {
		e.preventDefault();

		this.setState({
			isLoading: true,
		});

		const formData = new FormData(e.target);

		const payload = {};

		for (const [key, value] of formData.entries()) {
			if (key === 'mainPicture') {
				if (value.size > 0 && this.state.mainPicture) {
					payload[key] = value;
				} else {
					payload[key] = null;
				}
			} else if (key === 'profilePictures') {
				payload[key] = [...this.state.profilePictures];
			} else {
				payload[key] = value;
			}
		}

		if (!payload.nameOfTheCandle) {
			this.setState({ nameOfTheCandleError: 'יש להזין שם מלא', isLoading: false });
		}

		if (payload.nameOfTheCandle && !this.state.profilePicturesError && !this.state.mainPictureError) {
			const leadResult = await this.props.createItem<any>({
				collection: COLLECTIONS.LEADS,
				data: payload,
			});

			if (leadResult.data.data.lead === LEAD_CONSTANTS.CREATED) {
				this.setState({
					isShowSenededFormPopup: true,
					isLoading: false,
				});

				dataLayerEvents.onFormSended();
			}
		} else {
			this.setState({
				isLoading: false,
			});
		}
	};

	profilePicturesValidation = profilePictures => {
		let error = '';

		if (profilePictures.length > 5) {
			error = `מקסימום 4 קבצים`;
		} else if (profilePictures.length > 0 && profilePictures.length <= 5) {
			_.map(profilePictures, i => {
				if (i.size > 5 * (1024 * 1024)) {
					error = `יש לצרף תמונה בגודל של עד 5 מגה`;
				}
			});
		} else {
			error = '';
		}

		this.setState({ profilePicturesError: error });
	};

	mainPictureValidation = file => {
		let error = '';

		if (file.size > 5 * (1024 * 1024)) {
			error = `יש לצרף תמונה בגודל של עד 5 מגה`;
		} else {
			error = '';
		}

		this.setState({ mainPictureError: error });
	};

	getYears = (a: number, b: number) => {
		const yearsArr: any = [];

		for (let i = 0; i <= b - a; i += 1) {
			const year = (a + i).toString();

			yearsArr.push({ value: year, label: year });
		}
		// yearsArr.reverse();
		return yearsArr;
	};

	render() {
		const {
			isLoading,
			isShowSenededFormPopup,
			isShowError,
			nameOfTheCandleValue,
			mainPicture,
			freeText,
			profilePictures,
			senderName,
			profilePicturesError,
			nameOfTheCandleError,
			mainPictureError,
		} = this.state;

		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		const placeholderNameOfTheCandle = 'שם הנופל <span>(שדה חובה)</span>';

		return (
			<div className={css.formWrapper}>
				<form className={css.form} onSubmit={this.onFormSubmit}>
					<div className={css.formInner} style={{ display: isShowSenededFormPopup && 'none' }}>
						<div className={css.row}>
							<div
								className={css.fieldWrapper}
								onClick={() => {
									this.setState({ nameOfTheCandleError: '' });
								}}
							>
								<label className={cn(css.textField, css.name)}>
									{!nameOfTheCandleValue && (
										<div
											className={css.placeholder}
											dangerouslySetInnerHTML={{ __html: placeholderNameOfTheCandle }}
										></div>
									)}
									<input
										name="nameOfTheCandle"
										value={nameOfTheCandleValue}
										onChange={e => this.setState({ nameOfTheCandleValue: e.currentTarget.value })}
									/>
								</label>
								{nameOfTheCandleError && <p className={css.error}>{nameOfTheCandleError}</p>}
							</div>

							<ReactSelect
								name="birthYear"
								onChange={(e: any) => this.setState({ birthYear: e.value })}
								// onBlur={(e: any) => _.invoke(props, 'input.onBlur', e)}
								options={this.getYears(1890, 2024)}
								placeholder="שנת לידה"
								classNamePrefix="react-select"
								styles={{
									option: (provided, { isSelected }) => ({
										...provided,
										background: isSelected ? 'rgb(214, 214, 214)' : '#fff',
										color: 'rgb(95, 95, 95)',
									}),
									singleValue: (provided, state) => {
										const opacity = state.isDisabled ? 0.5 : 1;
										const transition = 'opacity 300ms';

										return { ...provided, opacity, transition };
									},
								}}
								className={css.selectC}
							/>

							<ReactSelect
								name="deathYear"
								onChange={(e: any) => this.setState({ deathYear: e.value })}
								options={this.getYears(1890, 2024)}
								placeholder="שנת לידה"
								classNamePrefix="react-select"
								styles={{
									option: (provided, { isSelected }) => ({
										...provided,
										background: isSelected ? 'rgb(214, 214, 214)' : '#fff',
										color: 'rgb(95, 95, 95)',
									}),
									singleValue: (provided, state) => {
										const opacity = state.isDisabled ? 0.5 : 1;
										const transition = 'opacity 300ms';

										return { ...provided, opacity, transition };
									},
								}}
								className={css.selectC}
							/>
						</div>
						<div className={css.row}>
							<label className={cn(css.textField, css.textArea)}>
								{!freeText && <div className={cn(css.placeholder, css.textareaPlaceholder)}>מילים לזיכרון</div>}
								<textarea
									name="freeText"
									value={freeText}
									onChange={e => this.setState({ freeText: e.currentTarget.value })}
								/>
							</label>
						</div>
						<div className={css.row}>
							<div className={cn(css.mediaField, css.mainPicture)}>
								<div className={cn(css.fileOutOfLimit, mainPictureError && css.fileOutOfLimitActive)}>
									{mainPictureError}
								</div>
								<label className={cn(css.controlLabel)} htmlFor="mainPictureID">
									<div className={css.fileNameWrapper}>
										<div className={css.text}></div>
										<div className={css.buttonPlus}>
											<img src={uploadIcon} alt="Upload" />
										</div>
									</div>
									<div className={css.placeholder}>צרפו תמונת פרופיל עד 5MB</div>
									<input
										id="mainPictureID"
										name="mainPicture"
										type="file"
										accept="image/*"
										onChange={e => {
											this.setState({ mainPicture: e.currentTarget?.files[0] });
											this.mainPictureValidation(e.currentTarget.files[0]);
										}}
									/>
								</label>
								<div className={css.uploadedFiles}>
									<button className={css.file} type="button" onClick={() => this.setState({ mainPicture: null })}>
										{mainPicture && mainPicture.name && (
											<>
												<img src={checkIcon} alt="check" /> <span>{mainPicture.name}</span>
											</>
										)}
									</button>
								</div>
							</div>

							<div className={cn(css.mediaField, css.profilePictures)}>
								<div className={cn(css.profilePicturesError, profilePicturesError && css.active)}>
									{profilePicturesError}
								</div>
								<label className={cn(css.controlLabel)} htmlFor="profilePicturesID">
									<div className={css.fileNameWrapper}>
										<div className={css.text}></div>
										<div className={css.buttonPlus}>
											<img src={uploadIcon} alt="Upload" />
										</div>
									</div>
									{isMobile && profilePicturesError ? null : (
										<div className={css.placeholder}>צרפו עד 4 תמונות, עד 5MB</div>
									)}

									<input
										id="profilePicturesID"
										name="profilePictures"
										type="file"
										accept="image/*"
										multiple
										onChange={e => {
											this.setState({ profilePictures: e.currentTarget.files });
											this.profilePicturesValidation(e.currentTarget.files);
										}}
									/>
								</label>
								<div className={cn(css.uploadedFiles)}>
									{_.map(profilePictures, (item, index: number) => {
										return (
											<div key={`${item.name}|${index}`}>
												{item.name && (
													<button
														className={cn(css.file, item.size > 5 * (1024 * 1024) && css.error)}
														type="button"
														onClick={() => {
															const filteredFiles = _.remove(
																[...profilePictures],
																(i: any) => i.name !== item.name
															);
															this.setState({ profilePictures: filteredFiles });
															this.profilePicturesValidation(filteredFiles);
														}}
													>
														{item.name && (
															<>
																<img src={checkIcon} alt="check" /> <span>{item.name}</span>
															</>
														)}
													</button>
												)}
											</div>
										);
									})}
								</div>
							</div>

							<label className={cn(css.textField, css.senderName)}>
								{!senderName && <div className={css.placeholder}>שם הכותב</div>}
								<input
									name="senderName"
									type="text"
									value={senderName}
									onChange={e => this.setState({ senderName: e.currentTarget.value })}
								/>
							</label>
						</div>
						<button type="submit" className={css.submitButton} disabled={isLoading}>
							{!isLoading ? (
								'שליחה'
							) : (
								<div className={css.ldsRipple}>
									<div></div>
									<div></div>
								</div>
							)}
						</button>
						<div className={css.bottomText}>
							אין להעלות תמונות ומידע הסותרים את{' '}
							<a href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html" target="_blank" rel="noreferrer">
								התקנון
							</a>
						</div>
					</div>
				</form>

				<LeadCreatedPopUp
					setActiveLeadCreatedPopUp={() => this.onClickCloseCreatedPopUp()}
					setActiveCreateLeadPopUp={this.props.setActiveCreateLeadPopUp}
					activeLeadCreatedPopUp={this.state.isShowSenededFormPopup}
				/>
			</div>
		);
	}
}

type FormComponentProps = {
	className?: string,
	children?: React.Node,
	handleSubmit: Function,
	onSubmit: Function,
};

const FormComponent = (props: FormComponentProps) => {
	return (
		<form className={props.className} onSubmit={props.handleSubmit(props.onSubmit)}>
			{props.children}
		</form>
	);
};

FormComponent.defaultProps = {
	className: '',
	children: null,
};

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.submitErrors';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
	};
};

const mapDispatch = {
	createItem,
	updateRealTimeDataBase,
};

export default connect(mapState, mapDispatch)(ContentForm);
