// @flow

import _ from 'lodash';

export class URLParams {
	static parse(searchStr: string): { [key: string]: any } {
		const iterable = new URLSearchParams(searchStr);

		return [...iterable].reduce((obj, [key, val]) => {
			obj[key] = val;
			return obj;
		}, {});
	}

	static stringify(data: { [key: string]: any }): string {
		const searchParams: URLSearchParams = new URLSearchParams('');

		_.forEach(data, (value, key) => searchParams.append(key, value));

		return searchParams.toString();
	}
}
