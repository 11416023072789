// @flow
import * as React from 'react';
import classNames from 'classnames';

import * as css from './Text.scss';

type Props = {
	className?: string,
	tag?: string,
	children?: React.Node,
	type?: 'S62' | 'S46' | 'S32' | 'S24' | 'S22' | 'S18' | 'S16' | 'S12' | 'N' | 'S1',
	weight?: 'light' | 'regular' | 'medium' | 'semibold' | 'bold',
};

const Text = ({ className, tag = 'p', children, type, weight }: Props) => {
	const T = tag;

	return <T className={classNames(css.text, css[type], css[weight], className)}>{children}</T>;
};

Text.defaultProps = {
	className: '',
	tag: 'p',
	children: null,
	type: 'N',
	weight: 'regular',
};

export { Text };

/**
 * L3: 32px;
 * L2: 23px;
 * L1: 19px;
 * N: 16px;
 * S1: 14px;
 */
