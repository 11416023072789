/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import checkboxCheckedImg from 'assets/client/images/checkbox-checked.png';
import * as css from './Checkbox.scss';

type Props = {
	input: {
		name: string,
		onChange: Function,
		checked?: boolean,
	},
	meta: {
		touched?: boolean,
		error?: string,
		submitFailed?: boolean,
	},
	className?: string,
	label: React.Element<*>,
};

export default class Checkbox extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
	};

	render() {
		const { className, label } = this.props;
		const isActive = _.get(this.props, 'input.checked');

		return (
			<label className={classNames(css.checkbox, isActive && css.active, className)}>
				<div className={css.control}>
					<img src={checkboxCheckedImg} alt="" className={css.checkboxChecked} />
				</div>
				<p>{label}</p>
				<input {...this.props.input} type="checkbox" />
			</label>
		);
	}
}
