// @flow
import React, { useState, useEffect } from 'react';
import { Responsive } from 'client/components/common';

import thanksPopUpImage from 'assets/client/images/thanksPopUp/thanksPopUp.png';
import thanksPopUpImageMobile from 'assets/client/images/thanksPopUp/thanksPopUpMobile.png';
import cn from 'classnames';
import css from './LeadCreatedPopUp.scss';

type Props = {
	activeLeadCreatedPopUp: boolean,
	setActiveLeadCreatedPopUp: any,
	setActiveCreateLeadPopUp: any,
};

export default function LeadCreatedPopUp(props: Props) {
	const [isClose, setIsClose] = useState(true);
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const { activeLeadCreatedPopUp, setActiveLeadCreatedPopUp, setActiveCreateLeadPopUp } = props;

	const onClickCloseButton = () => {
		setIsClose(true);
		setTimeout(() => {
			setActiveLeadCreatedPopUp(false);
			setActiveCreateLeadPopUp(false);
		}, 200);
	};

	useEffect(() => {
		setTimeout(() => {
			if (activeLeadCreatedPopUp) {
				setIsClose(false);
			}
		}, 200);
	}, [activeLeadCreatedPopUp]);

	return (
		<div className={cn(css.leadCreatedPopUp, activeLeadCreatedPopUp && css.active)}>
			<div className={css.bg} onClick={onClickCloseButton}></div>
			<div className={css.popUpWindow} style={{ background: `url(${!isMobile ? thanksPopUpImage : thanksPopUpImageMobile})` }}>
				<div
					className={cn(css.closeButton, isClose && css.animationCloseButton, !isClose && css.animationCloseButtonFalse)}
					onClick={onClickCloseButton}
				>
					<span className={css.firstLine}></span>
					<span className={css.secondLine}></span>
				</div>
				<div className={css.leadCreated}>
					<div className={css.textBlock}>
						<p className={css.title}>תודה על השיתוף,</p>
						<p className={css.description}>התוכן מועבר לאישור ולאחריו יתפרסם בעמוד</p>
					</div>
				</div>
			</div>
		</div>
	);
}
