// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Input from './MultipleMediaField';

type Props = {
	className?: string,
	name: string,
	placeholder?: any,
	// disabled?: boolean,
	sizeLimit: number,
	// onlyFileUpload?: boolean,
	// onSetFileSize?: Function,
	// onShowSizeLimitPopup?: Function,
};

export default class MultipleMediaField extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		placeholder: '',
		// disabled: false,
		// onlyFileUpload: false,
		// onSetFileSize: () => {},
		// onShowSizeLimitPopup: () => {},
	};

	render() {
		const {
			className,
			name,
			placeholder,
			// disabled,
			// onlyFileUpload,
			// onSetFileSize,
			// onShowSizeLimitPopup,
			sizeLimit,
		} = this.props;
		const InputComponent = props => (
			<Input
				{...props}
				className={className}
				placeholder={placeholder}
				// onlyFileUpload={onlyFileUpload}
				// disabled={disabled}
				// onSetFileSize={onSetFileSize}
				// onShowSizeLimitPopup={onShowSizeLimitPopup}
				sizeLimit={sizeLimit}
			/>
		);

		return <Field name={name} component={InputComponent} />;
	}
}
