import { CAN_USE_DOM } from 'common/constants';

export function parseURL(url) {
	if (!CAN_USE_DOM) {
		return {};
	}
	const parser = document.createElement('a');
	const searchObject = {};
	let field;
	let value;
	let queries = null;

	// Let the browser do the work
	parser.href = url;

	// Convert query string to object
	queries = window.decodeURIComponent(parser.search.replace(/^\?/, '')).split('&');

	for (let i = 0; i < queries.length; i += 1) {
		[field, value] = queries[i].split('=');
		if (field) {
			searchObject[field] = value;
		}
	}
	return {
		protocol: parser.protocol,
		host: parser.host,
		hostname: parser.hostname,
		port: parser.port,
		pathname: parser.pathname,
		search: parser.search,
		searchObject,
		hash: parser.hash,
	};
}
