// @flow
export const CHECK_AUTH_STATE = {
	START: 'CHECK_AUTH_STATE.START',
	SUCCESS: 'CHECK_AUTH_STATE.SUCCESS',
	FAIL: 'CHECK_AUTH_STATE.FAIL',
};

export const LOGIN = {
	START: 'LOGIN.START',
	SUCCESS: 'LOGIN.SUCCESS',
	FAIL: 'LOGIN.FAIL',
};

export const LOGOUT = {
	START: 'LOGOUT.START',
	SUCCESS: 'LOGOUT.SUCCESS',
	FAIL: 'LOGOUT.FAIL',
};

export const SET_AUTH_BUSY_STATE = 'SET_AUTH_BUSY_STATE';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
