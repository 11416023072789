/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow

import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { APPROVED } from 'admin/constants';
import { CAN_USE_DOM } from 'common/constants';
// import SearchBar from '../../SearchBar';
import { Responsive } from 'client/components/common';
import Item from 'client/components/common/Item';
import FullWidthItem from 'client/components/common/FullWidthItem';
import leftArrow from 'assets/client/images/firstScreen/left-arrow.png';
import scrollTopArrow from 'assets/client/images/firstScreen/scrollTopArrow.png';
import CreateLeadPopUp from 'client/components/common/CreateLeadPopUp';
import { ROOT_ROUTE } from 'client/constants/routes';
import Search from 'client/components/common/Search';
import { dataLayerEvents } from 'common/utils/gtag';
import { sleep } from 'src/common/utils';
import { URLParams } from 'client/utils';

import css from './FirstScreen.scss';

type Props = {
	leads: Leads,
};

const Loader = () => (
	<div className={css.ldsRing}>
		<div />
		<div />
		<div />
		<div />
	</div>
);

const smallTextDesk = `יום הזיכרון לחללי מערכות ישראל <br /> ופעולות האיבה תשפ״ד 2024`;
const smallTextMob = `יום הזיכרון לחללי מערכות ישראל <br /> ופעולות האיבה תשפ״ד 2024`;

const FirstScreen = (props: Props) => {
	const { leads } = props;
	const history = useHistory();

	const [items, setItems] = useState([]); // Just items
	const [loadedItems, setLoadedItems] = useState([]); // All items what loaded
	const [chunkedItems, setChunkedItems] = useState([]); // Chunked items for infinite scroll

	// when use search
	const [filteredItems, setFilteredItems] = useState([]);

	// active index for slider in ItemPopUp
	const [isActiveAnimationActive, setIsAnimationActive] = useState(false);

	// Is search page or no
	const [isSearch, setIsSearch] = useState(false);
	// If don't have results
	const [isNoResults, setIsNoResults] = useState(false);
	// Loader
	const [isLoading, setIsLoading] = useState(false);

	const [activeCreateLeadPopUp, setActiveCreateLeadPopUp] = useState(false);
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const isMobile = Responsive.isMatching(Responsive.MOBILE);

	// Button show
	const [isShowScrollTopButton, setIsShowScrollTopButton] = useState(false);

	useEffect(() => {
		// Show/Hide go to top button
		if (CAN_USE_DOM && isDesktop) {
			const handleScroll = () => {
				const currentScrollY = window.scrollY;
				if (currentScrollY > 200) {
					setIsShowScrollTopButton(true);
				} else {
					setIsShowScrollTopButton(false);
				}
			};

			window.addEventListener('scroll', handleScroll, { passive: true });

			return () => window.removeEventListener('scroll', handleScroll);
		}
	}, []);

	const scrollToTop = () => {
		if (CAN_USE_DOM) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	// Red button click for a show CreateLeadPopUp
	const onButtonClick = () => {
		setIsAnimationActive(true);

		setTimeout(() => {
			setIsAnimationActive(false);
		}, 400);
		setTimeout(() => {
			setActiveCreateLeadPopUp(true);
		}, 200);
		dataLayerEvents.onCreateLeadClick();
	};

	const onItemClick = (index: number, id) => {
		// history.push(ITEM_ROUTE.replace(':id', id));
		const search = { p: id };

		history.replace({
			pathname: ROOT_ROUTE,
			search: `?${URLParams.stringify(search)}`,
		});
	};

	const onBackButtonClick = () => {
		setIsSearch(false);
		setFilteredItems([]);
		dataLayerEvents.onBackClick();
		setIsNoResults(false);
	};

	// Load func when user scrolling to bottom, need to load new items
	const loadFunc = async page => {
		await sleep(200);
		const nextPortion = chunkedItems[page];
		if (_.size(nextPortion)) {
			setLoadedItems(currentItems => [...currentItems, ...nextPortion]);
		}
	};

	useEffect(() => {
		if (leads.list.length) {
			const originalItems = leads.list;
			const chunkedArr = _.chunk(originalItems, 30);

			setItems(originalItems);
			setLoadedItems(chunkedArr[0]);
			setChunkedItems(chunkedArr);
		}
	}, [leads]);

	return (
		<div>
			<div className={css.content}>
				<p className={css.smallText} dangerouslySetInnerHTML={{ __html: isMobile ? smallTextMob : smallTextDesk }}></p>
				<h1 className={css.title}>החלל שלי</h1>
				<p className={css.subtitle}>ממלחמת העצמאות ועד חרבות ברזל</p>
				<div className={cn(css.redButton, isActiveAnimationActive && css.redButtonAnimation)} onClick={onButtonClick}>
					הדליקו נר זיכרון
					<img src={leftArrow} alt="left arrow" className={cn(css.leftArrow, isActiveAnimationActive && css.leftArrowActive)} />
				</div>
				{/* <p className={css.delayNotation}>בשל העומס, פרסום "נר הזיכרון" עלול להתעכב. עמכם הסליחה</p> */}
				<div className={css.searchWrapper}>
					<Search
						leads={items}
						setIsSearch={setIsSearch}
						setFilteredItems={setFilteredItems}
						setIsLoading={setIsLoading}
						setIsNoResults={setIsNoResults}
						isLoading={isLoading}
						isSearch={isSearch}
					/>
					<button
						type="button"
						onClick={() => onBackButtonClick()}
						style={{ display: isSearch ? 'flex' : 'none' }}
						className={cn(css.backButton, isSearch && css.visible)}
					>
						<p>חזרה לראשי </p>
						<img
							src={leftArrow}
							alt="left arrow"
							className={cn(css.leftArrow, css.leftArrowSmall, isActiveAnimationActive && css.leftArrowActive)}
						/>
					</button>
				</div>
				<div className={css.itemsWrapper}>
					{isLoading && <Loader />}

					{!_.isEmpty(loadedItems) && (
						<InfiniteScroll
							className={cn(css.items, (isSearch || isLoading) && css.hide)}
							style={{ display: (isSearch || isLoading) && 'none' }}
							loadMore={loadFunc}
							hasMore={loadedItems.length < items.length}
							loader={
								<div className={css.itemsLoader} key={0}>
									<Loader />
								</div>
							}
						>
							{_.map(loadedItems, (item, index: number) => (
								<div className={css.itemWrapper} onClick={() => onItemClick(index, item.id)} key={item.id}>
									<Item item={item} key={item.id} />
								</div>
							))}
						</InfiniteScroll>
					)}
					<div className={cn(css.fullWidthItems, isSearch && !isLoading && css.visible)}>
						{_.map(filteredItems, item => {
							return item.leadStatus === APPROVED && <FullWidthItem item={item} key={item.id} />;
						})}
					</div>
					{isNoResults && <div className={css.noResults}>לא נמצאו תוצאות</div>}

					<CreateLeadPopUp setActiveCreateLeadPopUp={setActiveCreateLeadPopUp} activeCreateLeadPopUp={activeCreateLeadPopUp} />
					{isDesktop && (
						<button
							className={cn(css.scrollTopButton, isShowScrollTopButton && css.visible)}
							type="button"
							onClick={scrollToTop}
						>
							<img src={scrollTopArrow} className={css.scrollTopArrow} alt="" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default FirstScreen;
