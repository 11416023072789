// @flow
import Api from './ApiWorker';

declare type UpdateRealItem = {| value: string, id: string |};

export const updateRealTimeDataBase = (params: UpdateRealItem) =>
	Api.send<any>({
		url: '/realtime/update',
		method: 'POST',
		body: params,
		secure: true,
	});
export const getRealTimeDataBase = (params: UpdateRealItem) =>
	Api.send<any>({
		url: '/realtime/get',
		method: 'POST',
		body: params,
		secure: true,
	});
