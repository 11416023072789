// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';
import { reducer as formReducer } from 'redux-form';

import user from './user';
import leads from './leads';
import item from './item';
// import settings from './settings';

const reducer: Reducer<ClientStore, Action<{ type: string, payload: any }>> = combineReducers({
	user,
	// settings,
	leads,
	item,
	form: formReducer,
});

export default reducer;
