// @flow
import React, { useState, useEffect } from 'react';
import { getMainPicCropedUrl } from 'common/utils';
import _ from 'lodash';
import defaultImage from 'assets/client/images/firstScreen/final_candel.png';
import playButton from 'assets/client/images/play-button.png';
import css from './Item.scss';

type Props = {
	item: LeadInfo,
};
export default function Item(props: Props) {
	const { item } = props;
	const [isHasVideo, setIsHasVideo] = useState(false);

	useEffect(() => {
		_.map(item?.profilePictures, pictures => {
			if (pictures?.name?.includes('videos')) {
				setIsHasVideo(true);
			}
		});
	}, []);

	return (
		<div className={css.item}>
			<div className={css.imageWrapper}>
				<img
					src={item?.mainPicture?.file?.name ? getMainPicCropedUrl(item, item?.faceIndex) : defaultImage}
					alt=""
					className={css.mainPicture}
				/>
				{isHasVideo && <img src={playButton} alt="video" className={css.playButton} />}
			</div>
			<p className={css.name}>{item?.nameOfTheCandle} ז”ל</p>
		</div>
	);
}
