// @flow
import _ from 'lodash';
import { connect } from 'react-redux';
import ItemPopUp from './ItemPopUp';

const mapState = (state: ClientStore) => ({
	leads: _.get(state, 'leads'),
	item: _.get(state, 'item.item'),
});
const mapDispatch = {};

export default connect(mapState, mapDispatch)(ItemPopUp);
