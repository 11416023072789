// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: Leads = {
	isBusy: false,
	list: [],
	total: 0,
};

type Action =
	| { type: typeof actions.GET_ITEM.START, payload: { collection: string } }
	| { type: typeof actions.GET_ITEM.SUCCESS, payload: any }
	| { type: typeof actions.GET_ITEM.FAIL, payload: { collection: string } };

export default function itemReducer(state: Leads = initialState, action: Action) {
	return produce<any>(state, (draft: LeadItem) => {
		switch (action.type) {
			case actions.GET_ITEM.START:
				draft.isBusy = true;
				break;

			case actions.GET_ITEM.SUCCESS:
				draft.item = action.payload.item;
				draft.isBusy = false;
				break;

			case actions.GET_ITEM.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
