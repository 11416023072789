// @flow
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import ContentForm from '../ContentForm';
import css from './CreateLeadPopUp.scss';

type Props = {
	activeCreateLeadPopUp: boolean,
	setActiveCreateLeadPopUp: any,
};

export default function CreateLeadPopUp(props: Props) {
	const [isClose, setIsClose] = useState(true);
	const [isRemoveForm, setRemoveForm] = useState(false);
	const { activeCreateLeadPopUp, setActiveCreateLeadPopUp } = props;

	const onClickCloseButton = () => {
		setIsClose(true);
		setTimeout(() => {
			setActiveCreateLeadPopUp(false);
		}, 200);
	};

	useEffect(() => {
		setTimeout(() => {
			if (props.activeCreateLeadPopUp) {
				setIsClose(false);
			}
		}, 200);

		setTimeout(() => {
			if (props.activeCreateLeadPopUp) {
				setRemoveForm(true);
			} else {
				setRemoveForm(false);
			}
		}, 100);
	}, [props.activeCreateLeadPopUp]);

	return (
		<div className={cn(css.createLeadPopUp, activeCreateLeadPopUp && css.active)}>
			<div className={css.bg} onClick={onClickCloseButton}></div>
			<div className={css.popUpWindow}>
				<div
					className={cn(css.closeButton, isClose && css.animationCloseButton, !isClose && css.animationCloseButtonFalse)}
					onClick={onClickCloseButton}
				>
					<span className={css.firstLine}></span>
					<span className={css.secondLine}></span>
				</div>
				<div className={css.form}>
					<h2 className={css.title}>הדליקו נר זיכרון, כתבו מילות פרידה והעלו תמונות</h2>
					{isRemoveForm && <ContentForm setActiveCreateLeadPopUp={setActiveCreateLeadPopUp} />}
				</div>
			</div>
		</div>
	);
}
