// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Input from './Select';

type Props = {
	name: string,
	// label: React.Element<*>,
	className?: string,
};

export default class Select extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
	};

	render() {
		const { name, className } = this.props;
		const InputComponent = props => <Input {...props} className={className} name={name} />;

		return <Field {...this.props} name={name} component={InputComponent} />;
	}
}
