/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
// @flow

import * as React from 'react';
import Slider from 'react-slick';

import { Responsive } from 'client/components/common';

import slide1Desk from 'assets/client/images/heroSlider/dekstop-1.jpg';
import slide2Desk from 'assets/client/images/heroSlider/dekstop-2.jpg';
import slide3Desk from 'assets/client/images/heroSlider/dekstop-3.jpg';
import slide4Desk from 'assets/client/images/heroSlider/dekstop-4.jpg';
import slide5Desk from 'assets/client/images/heroSlider/dekstop-5.jpg';

import slide1Mob from 'assets/client/images/heroSlider/mobile-1.png';
import slide2Mob from 'assets/client/images/heroSlider/mobile-2.png';
import slide3Mob from 'assets/client/images/heroSlider/mobile-3.png';
import slide4Mob from 'assets/client/images/heroSlider/mobile-4.png';
import slide5Mob from 'assets/client/images/heroSlider/mobile-5.png';

import * as css from './HeroSlider.scss';

const HeroSlider = () => {
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const slidesBgsDesk = [slide1Desk, slide2Desk, slide3Desk, slide4Desk, slide5Desk];
	const slidesBgsMob = [slide1Mob, slide2Mob, slide3Mob, slide4Mob, slide5Mob];

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		initialSlide: 0,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 3000,
	};
	return (
		<div className={css.sliderWrapper}>
			<Slider {...settings}>
				{isMobile
					? slidesBgsMob.map((slide, index) => {
							return (
								<div className={css.slideWrapper} key={index}>
									<div className={css.slideInner} style={{ backgroundImage: `url(${slide})` }}></div>
								</div>
							);
					  })
					: slidesBgsDesk.map((slide, index) => {
							return (
								<div className={css.slideWrapper} key={index}>
									<div className={css.slideInner} style={{ backgroundImage: `url(${slide})` }}></div>
								</div>
							);
					  })}
			</Slider>
		</div>
	);
};

export default HeroSlider;
