// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: User = {
	name: '',
	phoneNumber: '',
	email: '',
	picture: '',
	uid: '',
	loginMethod: 'anonymous',
	auth: {
		isBusy: false,
		error: '',
		isLoggedIn: false,
		isInitialCheckComplete: false,
		isLoggedInByAuthCheck: false,
	},
};

type Action =
	| { type: typeof actions.SET_AUTH_ERROR, payload: string }
	| { type: typeof actions.SET_AUTH_BUSY_STATE, payload: boolean }
	| { type: typeof actions.CHECK_AUTH_STATE.START, payload: void }
	| { type: typeof actions.CHECK_AUTH_STATE.SUCCESS, payload: AdminData }
	| { type: typeof actions.LOGIN.SUCCESS, payload: AdminData }
	| { type: typeof actions.LOGOUT.SUCCESS, payload: void };

export default function adminReducer(state: User = initialState, action: Action) {
	return produce<any>(state, (draft: {| ...User |}) => {
		switch (action.type) {
			case actions.SET_AUTH_ERROR:
				draft.auth.error = action.payload;
				break;

			case actions.SET_AUTH_BUSY_STATE:
				draft.auth.isBusy = action.payload;
				break;

			case actions.CHECK_AUTH_STATE.START:
				draft.auth.isBusy = true;
				break;

			case actions.LOGIN.SUCCESS:
			case actions.CHECK_AUTH_STATE.SUCCESS:
				return {
					...action.payload,
					auth: {
						...state.auth,
						isBusy: false,
						isLoggedIn: true,
						isInitialCheckComplete: true,
						isLoggedInByAuthCheck: action.type === actions.CHECK_AUTH_STATE.SUCCESS,
					},
				};

			case actions.CHECK_AUTH_STATE.FAIL:
			case actions.LOGIN.FAIL:
			case actions.LOGOUT.SUCCESS:
				draft.auth.isBusy = false;
				draft.auth.isLoggedIn = false;
				draft.auth.isLoggedInByAuthCheck = false;
				draft.auth.isInitialCheckComplete = true;
				break;

			default:
				break;
		}
	});
}
