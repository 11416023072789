/* eslint-disable max-len */
import _ from 'lodash';

export const fileLinkIMGIX = (data, params) => {
	if (!data) {
		return null;
	}

	return data.selected === 'file' ? formatLink(_.get(data, 'file.url', ''), params) : _.get(data, 'remoteURL', '');
};

export const formatLink = (path, faceIndex = '0', params) => {
	const defaultParams = `?auto=compress&auto=format&w=250&h=350&&fit=facearea&facepad=10&faces=1&faceindex=${faceIndex}`;
	let link;
	if (path !== '') {
		// TODO: RETURN TO THIS IN FUTURE
		link = `https://memorialday2024.imgix.net/${path}${params ?? defaultParams}`;
	} else {
		link = 'https://ynet-memorial-candles-2024.firebaseapp.com/assets/client/share1200x600.jpeg';
	}

	return link;
};
