/* eslint-disable react/button-has-type */
// @flow
import * as React from 'react';
import classNames from 'classnames';

// import { Text } from '../Text';
import * as css from './Button.scss';

type Props = {
	className?: string,
	label: string,
	color?: 'green' | 'blue',
	disabled?: boolean,
	styleType?: 'primary',
	onClick?: () => any,
	type?: 'button' | 'submit' | 'reset',
	isOnClickAnimation?: boolean,
};
type State = {|
	isAnimation?: boolean,
|};
class Button extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
		color: 'green',
		disabled: false,
		styleType: 'primary',
		type: 'button',
		onClick: () => null,
		isOnClickAnimation: false,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			isAnimation: false,
		};
	}
	onClickFunc = () => {
		if (this.props.onClick) {
			this.props.onClick();
			this.setState({ isAnimation: true });
		}
	};
	render() {
		const { className, label, color, styleType, type, isOnClickAnimation, ...rest } = this.props;

		const { isAnimation } = this.state;
		return (
			<button
				{...rest}
				className={classNames(css.button, css[color], css[styleType], isAnimation && css.animation, className)}
				type={type}
				onClick={isOnClickAnimation ? this.onClickFunc : rest.onClick}
			>
				<p>{label}</p>
			</button>
		);
	}
}

export { Button };
