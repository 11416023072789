import React, { useState } from 'react';
import cn from 'classnames';

import Menu from './Menu';
import css from './BurgerMenu.scss';

export default function BurgerMenu() {
	const [active, setActive] = useState(false);

	const onBurgerClick = () => {
		setActive(!active);
	};
	return (
		<div className={css.burgerMenu}>
			<div className={cn(css.burgerLines, active && css.active)} onClick={onBurgerClick}>
				<span className={cn(css.line, css.line1)}></span>
				<span className={cn(css.line, css.line2)}></span>
				<span className={cn(css.line, css.line3)}></span>
			</div>

			<Menu active={active} />
		</div>
	);
}
