// @flow

import React, { useMemo } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { URLParams } from 'client/utils';
import { seoTitle, seoDescription, shareTitle, shareDescription, shareImage, shareUrl } from 'common/constants/seo';
import { APP_CONFIG } from 'common/constants';
import { OG_IMAGE_PARAMS } from 'client/constants/common';
import { formatLink } from 'common/utils';

import faviconImg from 'assets/favicon.png';

type Props = {| leads: Array<LeadInfo> |};

const HelmetComponent = (props: Props) => {
	const { leads } = props;
	const location = useLocation();
	const seoData = useMemo(() => {
		const urlParams = URLParams.parse(location.search);
		const index = _.findIndex(leads, ['id', _.get(urlParams, 'p', '')]);
		const lead = index >= 0 ? leads[index] : null;
		const regex = /( |<([^>]+)>)/gi;
		const leadName = lead?.nameOfTheCandle || '';

		if (lead) {
			return {
				title: leadName,
				href: `${APP_CONFIG.domains.client.hostname}?${_.get(location, 'search', '') || ''}`,
				description: lead.freeText.replace(regex, ' ').trim() || '',
				ogTitle: `${leadName} - החלל שלי - פרויקט ההנצחה השנתי של ynet`,
				ogDescription: lead.freeText.replace(regex, ' ').trim() || '',
				ogImage: formatLink(_.get(lead, 'mainPicture.file.name', ''), '0', OG_IMAGE_PARAMS) || shareImage,
				ogImageW: 200,
				ogImageH: 200,
				ogUrl: `${APP_CONFIG.domains.client.hostname}${_.get(location, 'search', '') || ''}`,
			};
		}

		return {
			title: seoTitle,
			href: APP_CONFIG.domains.client.hostname,
			description: seoDescription,
			ogTitle: shareTitle,
			ogDescription: shareDescription,
			ogImage: shareImage,
			ogImageW: 1200,
			ogImageH: 600,
			ogUrl: shareUrl,
		};
	}, [location, leads]);

	return (
		<Helmet
			title={seoData.title}
			link={[
				{
					rel: 'canonical',
					href: seoData.href,
				},
				{ rel: 'icon', type: 'image/png', href: faviconImg },
			]}
			meta={[
				{
					name: 'description',
					content: seoData.description,
				},
				{ property: 'og:title', content: seoData.ogTitle },
				{ property: 'og:description', content: seoData.ogDescription },
				{ property: 'og:image', content: seoData.ogImage },
				{ property: 'og:image:width', content: seoData.ogImageW },
				{ property: 'og:image:height', content: seoData.ogImageH },
				{ property: 'og:url', content: seoData.ogUrl },
				{ property: 'og:type', content: 'website' },
			]}
		/>
	);
};

export default HelmetComponent;
