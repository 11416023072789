const canonicalURl = 'https://ynet-memorial-candles-2024.firebaseapp.com';
// Seo title and description
export const seoTitle = 'החלל שלי 2024 – ynet';
export const seoDescription = 'יום הזיכרון לחללי מערכות ישראל 2024 תשפ"ד.  ביום הזיכרון ישראל עומדת מלכת כדי לתת מקום למי שאיננו';
// Share variables
export const shareUrl = 'https://ynet-memorial-candles-2024.firebaseapp.com/';
export const shareTitle = 'החלל שלי 2024 – ynet';
export const shareDescription = 'יום הזיכרון לחללי מערכות ישראל 2024 תשפ"ד.  ביום הזיכרון ישראל עומדת מלכת כדי לתת מקום למי שאיננו';

//! Attention: do not import og images as variable, write path here:
export const shareImage = `${canonicalURl}/assets/client/share1200x630.png`;
