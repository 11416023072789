// @flow
import { formatLink } from './format-link';

export const getMainPicCropedUrl = (item: LeadInfo, ...params: any) => {
	if (!item) return '';
	if (item.mainPicture.file) {
		if (!item.mainPictureCrop) {
			return formatLink(item.mainPicture.file.name, ...params);
		}

		const x = item.mainPictureCrop ? Math.round(item.mainPictureCrop.x) : 0;
		const y = item.mainPictureCrop ? Math.round(item.mainPictureCrop.y) : 0;
		const width = item.mainPictureCrop ? Math.round(item.mainPictureCrop.width) : 0;
		const height = item.mainPictureCrop ? Math.round(item.mainPictureCrop.height) : 0;

		return formatLink(item.mainPicture.file.name, '0', `?auto=compress&rect=${x},${y},${width},${height}`);
	}

	return '';
};
