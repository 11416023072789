// @flow
import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { dataLayerEvents } from 'common/utils/gtag';

import Socials from 'client/components/common/Socials';
import css from './BurgerMenu.scss';

type Props = {
	active: boolean,
};
const Menu = (props: Props) => {
	const { active } = props;

	const links = [
		{ text: 'יום הזיכרון 2023', link: 'https://memorialday2023.ynet.co.il/' },
		{ text: 'יום הזיכרון 2022', link: 'https://memorialday2022.ynet.co.il/' },
		{ text: 'יום הזיכרון 2021', link: 'https://z.ynet.co.il/mShort/content/2021/MemorialDayCandlelight/' },
		{ text: 'יום הזיכרון 2020', link: 'https://z.ynet.co.il/mShort/content/2020/MemorialDayCandlelight/' },
		{ text: 'יום הזיכרון 2019', link: 'https://z.ynet.co.il/mShort/content/2019/MemorialDayCandlelight/' },
		{ text: 'פנו אלינו', link: 'mailto:ofir-a@ynet.co.il' },
		{ text: 'תנאי שימוש', link: 'https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html' },
		{ text: 'מדיניות פרטיות', link: 'https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html' },
	];

	return (
		<div className={cn(css.menu, active && css.activeMenu)}>
			{_.map(links, item => {
				return (
					<a
						key={item.text}
						href={item.link}
						className={css.link}
						target="_blank"
						rel="noreferrer"
						onClick={() => dataLayerEvents.onMenuItemClick(item.text)}
					>
						{item.text}
					</a>
				);
			})}
			<Socials />
		</div>
	);
};

export default Menu;
