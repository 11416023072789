// @flow
import * as React from 'react';
import { reduxForm } from 'redux-form';

type Props = {
	config: {
		form: string,
	},
	onSubmit: Function,
	className?: string,
	children?: React.Node,
};

export default class Form extends React.PureComponent<Props> {
	reduxFormComponent: Function;

	static defaultProps = {
		children: null,
		className: '',
	};

	constructor(props: Props) {
		super(props);

		this.reduxFormComponent = reduxForm(props.config)(FormComponent);
	}

	render() {
		const { children, className, onSubmit } = this.props;
		const ReduxForm = this.reduxFormComponent;

		return (
			<ReduxForm className={className} onSubmit={onSubmit}>
				{children}
			</ReduxForm>
		);
	}
}

type FormComponentProps = {
	className?: string,
	children?: React.Node,
	handleSubmit: Function,
	onSubmit: Function,
};

const FormComponent = (props: FormComponentProps) => {
	return (
		<form className={props.className} onSubmit={props.handleSubmit(props.onSubmit)}>
			{props.children}
		</form>
	);
};

FormComponent.defaultProps = {
	className: '',
	children: null,
};
