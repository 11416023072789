// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: Leads = {
	isBusy: false,
	list: [],
	total: 0,
};

type Action =
	| { type: typeof actions.GET_LEADS.START, payload: { collection: string } }
	| { type: typeof actions.GET_LEADS.SUCCESS, payload: any }
	| { type: typeof actions.GET_LEADS.FAIL, payload: { collection: string } };

export default function leadsReducer(state: Leads = initialState, action: Action) {
	return produce<any>(state, (draft: Leads) => {
		switch (action.type) {
			case actions.GET_LEADS.START:
				draft.isBusy = true;
				break;

			case actions.GET_LEADS.SUCCESS:
				draft.list = action.payload.items;
				draft.total = action.payload.total;
				draft.isBusy = false;
				break;

			case actions.GET_LEADS.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
