// @flow
export * from './auth';
export * from './settings';
export * from './leads';

export const GET_ITEMS = {
	START: 'GET_ITEMS.START',
	SUCCESS: 'GET_ITEMS.SUCCESS',
	FAIL: 'GET_ITEMS.FAIL',
};
export const GET_ITEM = {
	START: 'GET_ITEM.START',
	SUCCESS: 'GET_ITEM.SUCCESS',
	FAIL: 'GET_ITEM.FAIL',
};

export const CREATE_ITEM = {
	START: 'CREATE_ITEM.START',
	SUCCESS: 'CREATE_ITEM.SUCCESS',
	FAIL: 'CREATE_ITEM.FAIL',
};
export const UPDATE_ITEM_REAL_TIME = {
	START: 'UPDATE_ITEM_REAL_TIME.START',
	SUCCESS: 'UPDATE_ITEM_REAL_TIME.SUCCESS',
	FAIL: 'UPDATE_ITEM_REAL_TIME.FAIL',
};

export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const DELETE_MEDIA = 'DELETE_MEDIA';
export const SET_POP_UP = 'SET_POP_UP';
